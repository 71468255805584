import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import "./style.css";

const DatasetDescriptionTab = (props) => {
  const [open, setOpen] = useState(false);
  const {
    dataset,
    dataset: { dataset_type },
  } = props;

  console.log("DatasetDescriptionTab", dataset, dataset_type);

  const autonomousCitation = () => {
    return (
      <>
        <h4>Citation / License</h4>
        <ul>
          <li>
            The Audi Autonomous Driving Dataset (A2D2);{" "}
            <a target="_blank" href="https://www.a2d2.audi">
              https://www.a2d2.audi
            </a>{" "}
            is released under the CC BY ND 4.0 license. For more information,
            please see{" "}
            <a
              target="_blank"
              href="https://creativecommons.org/licenses/by-nd/4.0/">
              https://creativecommons.org/licenses/by-nd/4.0/
            </a>
            . The Dataset was originally published in 2020 by J.Geyer et al.
            <a target="_blank" href="https://arxiv.org/abs/2004.06320​">
              https://arxiv.org/abs/2004.06320​
            </a>
          </li>
        </ul>
      </>
    );
  };

  const medicalCitation = () => {
    return (
      <>
        <h4>Citation / License</h4>
        <ul>
          <li>
            1. Lakhani P, Mongan J, Singhal C, Zhou Q, Andriole KP, Auffermann
            WF, Prasanna P, Pham T, Peterson M, Bergquist PJ, Cook TS,
            Ferraciolli SF, de Antonio Corradi GC, Takahashi M, Workman SS,
            Parekh M, Kamel S, Galant JH, Mas-Sanchez A, Benítez EC,
            Sánchez-Valverde M, Jaques L, Panadero M, Vidal M, Culiáñez-Casas M,
            Angulo-Gonzalez DM, Langer SG, de la Iglesia Vaya M, Shih G. The
            2021 SIIM-FISABIO-RSNA Machine Learning COVID-19 Challenge:
            Annotation and Standard Exam Classification of COVID-19 Chest
            Radiographs. [Internet]. OSF Preprints; 2021. Available from:{" "}
            <a target="_blank" href="osf.io/532ek">
              osf.io/532ek
            </a>
          </li>
        </ul>
      </>
    );
  };

  return (
    <Grid
      className="dataset-description"
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Box pt={3} pl={3} pb={5} pr={3} style={{ overflow: "auto" }}>
        <h4 style={{ marginTop: "0px" }}>Overview</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: dataset?.description?.overview,
          }}></div>

        <h4>Classes</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: dataset?.description?.classes,
          }}></div>

        <h4>Source</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: dataset?.description?.source,
          }}></div>

        {dataset?.description?.process_description && (
          <h4>How is the dataset created</h4>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: dataset?.description?.process_description,
          }}></div>

        {dataset?.description?.privacy_detail && <h4>Dataset Privacy</h4>}
        <div
          dangerouslySetInnerHTML={{
            __html: dataset?.description?.privacy_detail,
          }}></div>

        {dataset_type === "autonomous" && autonomousCitation()}
        {dataset_type === "medical" && medicalCitation()}
      </Box>
    </Grid>
  );
};

export default DatasetDescriptionTab;

import { Box, Grid, Paper } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import {
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import { GlobalContext } from "../../../Context/GlobalState";
import { numberWithCommas } from "utils/format";
import { generateGradient } from "utils/gradient";

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={8}
        y={0}
        dy={10}
        textAnchor="end"
        style={{
          fontSize: "12px",
          fontWeight: "400",
          fontFamily: '"Roboto", Arial',
        }}
        fill="#3576a7"
        transform="rotate(0)">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={1}
        y={0}
        dy={2}
        textAnchor="end"
        style={{
          fontSize: "12px",
          fontWeight: "400",
          fontFamily: '"Roboto", Arial',
        }}
        fill="#3576a7">
        {numberWithCommas(payload.value)}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, total }) => {
  if (active && payload && payload.length) {
    console.log("renderToolTip-entry", payload, label, total);
    return (
      <Paper className="defect-bar-tooltip" variant="outlined">
        <div
          style={{ color: payload[0]?.payload?.fill }}
          className={payload[0].name}
          key={`item-${payload[0].name}`}>
          <b>{`${payload[0].value}`}</b> images have
        </div>

        <div
          style={{ color: payload[0]?.payload?.fill }}
          className={payload[0].name}
          key={`item-${payload[0].name}-value`}>
          <b>{`${payload[0].payload?.name}`}</b> label
        </div>
      </Paper>
    );
  }

  return null;
};

const TotalClassPerImageTab = (props) => {
  const {
    dataset,
    dataset: { count },
  } = props;
  const { sidebar } = useContext(GlobalContext);
  const { open } = sidebar;
  const [classes, setClasses] = useState([]);
  const [colors, setColors] = useState(["#3576A7"]);

  useEffect(() => {
    const data = [];

    if (dataset?.data_items_label_count) {
      Object.entries(dataset?.data_items_label_count).forEach(
        ([key, value]) => {
          data.push({ name: key, value: value });
        }
      );
      setClasses(data);
      setColors(generateGradient("#363534", "#A9AFB0", data.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container direction="column" justify="flex-start">
      <Box
        mt={count?.defect ? 0 : 2}
        pl={0}
        pr={2}
        pb={1}
        className="inner-content">
        <ResponsiveContainer width={open ? "99.8%" : "100%"} height="90%">
          <ComposedChart
            layout="vertical"
            data={classes}
            margin={{ top: 20, bottom: 5, right: 20 }}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />

            <XAxis
              padding={{ left: 10, right: 0 }}
              axisLine={false}
              tickLine={true}
              type="number"
              interval={0}
              tick={<CustomizedAxisTick />}
            />

            <YAxis
              type="category"
              dataKey="name"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={<CustomizedYAxisTick />}
            />

            <Tooltip
              content={
                <CustomTooltip total={dataset?.unique_data_items_count} />
              }
            />

            <Bar barSize={35} dataKey="value" stackId="a" fill="#ffc47f">
              {classes &&
                classes.map((entry, index) => {
                  return <Cell fill={colors[index]} />;
                })}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Grid>
  );
};

export default TotalClassPerImageTab;

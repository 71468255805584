import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./style.css";
import { CircularProgress, Grid, TableSortLabel } from "@material-ui/core";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import DataSetRow from "./DataSetRow";
import { GlobalContext } from "../../Context/GlobalState";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", numeric: false, disablePadding: false, label: "ID" },
  { id: "title", numeric: false, disablePadding: false, label: "Name" },
  { id: "updated_date", numeric: false, disablePadding: false, label: "Date" },
  { id: "members", numeric: true, disablePadding: false, label: "Members" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}>
              {headCell.id !== "members" ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  classes={{
                    root: "sort-label",
                    icon: "sort-icon",
                    active: "sort-icon-active",
                  }}
                  IconComponent={ArrowDropDownOutlinedIcon}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
        <TableCell padding="checkbox"></TableCell>
      </TableRow>
    </TableHead>
  );
}

const DatasetList = (props) => {
  const {
    state: {
      datasets,
      datasets: { list, loading, next },
    },
    getUserDatasets,
  } = useContext(GlobalContext);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  useEffect(() => {
    if (list.length === 0 && !loading) {
      getUserDatasets();
    }
    if (list.length > 0 && datasets.next && page > 0 && !loading) {
      getUserDatasets(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  return list.length > 0 ? (
    <TableContainer
      className="table-dataset-list-container"
      onScroll={(event) => {
        const { target } = event;
        if (next) {
          const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
          const params = Object.fromEntries(urlSearchParams.entries());

          setPage(params.page);
        }

        console.log("Test", target.scrollHeight);
        if (target.scrollTop === target.scrollHeight - target.clientHeight) {
          console.log("END", target.scrollHeight);
        }
      }}>
      <Table className="table" aria-label="simple table">
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={list.length}
        />
        <TableBody>
          {stableSort(list, getComparator(order, orderBy)).map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <DataSetRow
                hover
                onClick={(event) => handleClick(event, row.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
                key={row.id}
                labelId={labelId}
                row={row}
              />
            );
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 2 * emptyRows }}>
              <TableCell className="empty-td" colSpan={6}>
                &nbsp;
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {loading && (
        <Grid container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
    </TableContainer>
  ) : (
    <Grid container justify="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );
};

export default DatasetList;

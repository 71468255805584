function hexToRgb(hex) {
  var r, g, b;
  hex = hex.replace("#", "");
  if (hex.length !== 3 && hex.length !== 6) {
    return [255, 255, 255];
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  r = parseInt(hex.substr(0, 2), 16);
  g = parseInt(hex.substr(2, 2), 16);
  b = parseInt(hex.substr(4, 2), 16);

  return [r, g, b];
}

function rgbToHex(color) {
  color[0] = color[0] > 255 ? 255 : color[0] < 0 ? 0 : color[0];
  color[1] = color[1] > 255 ? 255 : color[1] < 0 ? 0 : color[1];
  color[2] = color[2] > 255 ? 255 : color[2] < 0 ? 0 : color[2];

  return (
    zeroFill(color[0].toString(16), 2) +
    zeroFill(color[1].toString(16), 2) +
    zeroFill(color[2].toString(16), 2)
  );
}

function zeroFill(number, width) {
  width -= number.toString().length;
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
  }
  return number;
}

export function generateGradient(colorA, colorB, steps) {
  var result = [];

  colorA = hexToRgb(colorA);
  colorB = hexToRgb(colorB);
  steps -= 1;

  let rStep =
    (Math.max(colorA[0], colorB[0]) - Math.min(colorA[0], colorB[0])) / steps;
  let gStep =
    (Math.max(colorA[1], colorB[1]) - Math.min(colorA[1], colorB[1])) / steps;
  let bStep =
    (Math.max(colorA[2], colorB[2]) - Math.min(colorA[2], colorB[2])) / steps;

  result.push("#" + rgbToHex(colorA));

  var rVal = colorA[0],
    gVal = colorA[1],
    bVal = colorA[2];

  for (var i = 0; i < steps - 1; i++) {
    rVal =
      colorA[0] < colorB[0]
        ? rVal + Math.round(rStep)
        : rVal - Math.round(rStep);
    gVal =
      colorA[1] < colorB[1]
        ? gVal + Math.round(gStep)
        : gVal - Math.round(gStep);
    bVal =
      colorA[2] < colorB[2]
        ? bVal + Math.round(bStep)
        : bVal - Math.round(bStep);
    result.push("#" + rgbToHex([rVal, gVal, bVal]));
  }

  result.push("#" + rgbToHex(colorB));

  return result;
}

import axios from "axios";
import { API_URL, getAuthConfig } from "./config";

export const edgeDevicesService = {
  getUserEdgeDevices: async (nextPageUrl = null) => {
    const url = nextPageUrl || `${API_URL}/edge-device/`;
    return axios.get(url, getAuthConfig());
  },

  updateEdgeDeviceStatus: async (deviceId, status) => {
    return axios.patch(
      `${API_URL}/edge-devices/${deviceId}/`,
      { status },
      getAuthConfig()
    );
  },

  createEdgeDevice: async (deviceData) => {
    return axios.post(
      `${API_URL}/edge-device/`,
      {
        username: deviceData.username,
        password: deviceData.password,
        pue_constant: parseFloat(deviceData.pue_constant),
        tdp_of_cpu: parseFloat(deviceData.tdp_of_cpu),
        v_cpu_cores: parseInt(deviceData.v_cpu_cores),
        tdp_of_gpu: parseFloat(deviceData.tdp_of_gpu),
        v_gpu_cores: parseFloat(deviceData.v_gpu_cores),
        location: deviceData.location,
      },
      getAuthConfig()
    );
  },

  updateEdgeDevice: async (deviceId, data) => {
    return axios.patch(`${API_URL}/edge-device/${deviceId}/`, data, getAuthConfig());
  },

  deleteEdgeDevice: async (deviceId) => {
    return axios.delete(`${API_URL}/edge-device/${deviceId}/`, getAuthConfig());
  },
}; 

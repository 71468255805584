export const Defects = [
  {
    label: "porosity",
    diameter: 2.0,
    checked: true,
  },
  {
    label: "crack",
    diameter: 1.0,
    checked: false,
  },
  {
    label: "inclusion",
    diameter: 3.5,
    checked: false,
  },
];

export const Diseases = [
  {
    label: "None",
    checked: false,
  },
  {
    label: "Atelectasis",
    checked: false,
  },
  {
    label: "Cardiomegaly",
    checked: false,
  },
  {
    label: "Hernia",
    checked: false,
  },
  {
    label: "Nodule",
    checked: false,
  },
  {
    label: "Effusion",
    checked: false,
  },
  {
    label: "Infiltration",
    checked: false,
  },
  {
    label: "Emphysema",
    checked: false,
  },
  {
    label: "Pleural_Thickening",
    checked: false,
  },
  {
    label: "Pneumothorax",
    checked: false,
  },
  {
    label: "Mass",
    checked: false,
  },
  {
    label: "Fibrosis",
    checked: false,
  },
  {
    label: "Consolidation",
    checked: false,
  },
  {
    label: "Edema",
    checked: false,
  },
  {
    label: "Pneumonia",
    checked: false,
  },
];

export const Autonomous = [
  {
    label: "car",
    checked: false,
  },
  {
    label: "person",
    checked: false,
  },
  {
    label: "traffic light",
    checked: false,
  },
  {
    label: "traffic sign",
    checked: false,
  },
  {
    label: "truck",
    checked: false,
  },
];

export const Age = [
  {
    label: "Between",
    value: 0,
    checked: true,
  },
  {
    label: "and",
    value: 99,
    checked: true,
  },
];

export const defectStandards = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "low",
    value: "low",
  },
  {
    label: "medium",
    value: "medium",
  },
  {
    label: "high",
    value: "high",
  },
];

export const tubeVoltage = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "140kV",
    value: "140kV",
  },
  {
    label: "225kV",
    value: "225kV",
  },
  {
    label: "750kV",
    value: "750kV",
  },
];

export const machineParts = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "cylinderHead",
    value: "Cylinder Head",
  },
  {
    label: "wheel carrier",
    value: "wheel carrier",
  },
];

export const material = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "aluminum",
    value: "aluminum",
  },
  {
    label: "steel",
    value: "steel",
  },
];

export const company = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "GMAC",
    value: "GMAC",
  },
  {
    label: "ElringKlinger",
    value: "ElringKlinger",
  },
];

export const gender = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Male",
    value: "Male",
  },
];

export const defectsColors = {
  crack: {
    light: "rgba(72, 3, 85, 1)",
    dark: "rgba(65, 14, 82, 1)",
  },
  inclusion: {
    light: "rgba(255, 167, 0, 1)",
    dark: "rgba(255, 156, 41, 1)",
  },
  porosity: {
    light: "rgba(93, 211, 158, 1)",
    dark: "rgba(125, 208, 162, 1)",
  },
};

export const deepCopy = (array) => {
  const newArray = [];
  array.forEach((v, i) => {
    const val = typeof v === "object" ? Object.assign({}, v) : v;
    newArray.push(val);
  });
  return newArray;
};

export function parseJsonString(str) {
  if (str === null || str === undefined || str === "") {
    return null;
  }
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

export const countClasses = (classArray) => {
  let classes = 0;
  classArray &&
    Object.keys(classArray).forEach((item) => {
      if (classArray[item] > 0) {
        classes += 1;
      }
    });
  return classes;
};

export const checkCompetitors = (competitor_companies) => {
  if (!competitor_companies) {
    return false;
  }
  if (competitor_companies.length === 0) {
    return true;
  }
  return competitor_companies.every(
    (element) => element.company_type === competitor_companies[0].company_type
  );
};

export function LightenDarkenColor(color, percent) {
  // Add validation for hex color format
  const isValidHex = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(color);
  if (!isValidHex) {
    return color;
  }

  // Convert 3-digit hex to 6-digit
  if (color.length === 4) {
    color =
      "#" + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
  }

  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export function pickHex(color1, color2, weight) {
  var w1 = weight;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}

export function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
  var color1 = rgbColor1;
  var color2 = rgbColor2;
  var fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade = fade * 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  var diffRed = color2.red - color1.red;
  var diffGreen = color2.green - color1.green;
  var diffBlue = color2.blue - color1.blue;

  var gradient = {
    red: parseInt(Math.floor(color1.red + diffRed * fade), 10),
    green: parseInt(Math.floor(color1.green + diffGreen * fade), 10),
    blue: parseInt(Math.floor(color1.blue + diffBlue * fade), 10),
  };

  return (
    "rgb(" + gradient.red + "," + gradient.green + "," + gradient.blue + ")"
  );
}

export function isEmail(email) {
  const pattern = new RegExp(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  );

  return pattern.test(email);
}

export const trainingState = {
  stopped: 0,
  inQueue: 1,
  paused: 2,
  completed: 3,
  training: 4,
  aborted: 6,
};

export function encodeQuery(data) {
  const query = Object.entries(data)
    .reduce((query, [key, value]) => {
      if (Array.isArray(value)) {
        return (
          query +
          value
            .map(
              (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
            )
            .join("&") +
          "&"
        );
      }
      return query + `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }, "")
    .replace(/&$/, "");

  return query ? `?${query}` : ""; // Add "?" prefix if query exists
}

export const colorsPalate = [
  "#4BBDCC",
  "#4AB9CA",
  "#49B6C8",
  "#48B2C6",
  "#46AEC4",
  "#45AAC2",
  "#44A7C0",
  "#43A3BE",
  "#429FBC",
  "#419BBA",
  "#3F98B9",
  "#3E94B7",
  "#3D90B5",
  "#3C8CB3",
  "#3B89B1",
  "#3A85AF",
  "#3881AD",
  "#377DAB",
  "#367AA9",
  "#3576A7",
];

export function isDateBetween(from, to, check) {
  let fDate, lDate, cDate;
  fDate = Date.parse(from);
  lDate = Date.parse(to);
  cDate = Date.parse(check);

  if (cDate <= lDate && cDate >= fDate) {
    return true;
  }
  return false;
}

export const getStatusColor = (status) => {
  const statusColors = {
    1: "#39CD97",  // ONLINE
    2: "#FFA700",  // PENDING
    0: "#FF4444",  // OFFLINE
    "-1": "#FF4444" // FAILED - using same color as offline for error states
  };
  
  return statusColors[status.toString()] || "#707070";
};

export const getStatusString = (status) => {
  const statusMap = {
    1: "Online",
    2: "Pending",
    0: "Offline",
    "-1": "Failed"
  };
  
  return statusMap[status.toString()] || "Unknown";
};

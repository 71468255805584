import { Box, Paper } from "@material-ui/core";
import React from "react";
import Header from "../../Components/Header";
import { CustomTabs } from "../../Components/CustomTabs/CustomTabs";
import { CustomTab } from "../../Components/CustomTabs/CustomTab";
import { TabContext, TabPanel } from "@material-ui/lab";
import DatasetList from "../../Components/DatasetList";
import "./style.css";

const Datasets = (props) => {
  const [value, setValue] = React.useState("all");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box paddingX={7}>
      <Header />
      <Box pb={0} className="title-italic-24">
        All Datasets
      </Box>
      <TabContext value={value}>
        <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example">
          <CustomTab
            value="all"
            style={{ marginLeft: "1px", borderTopLeftRadius: "5px" }}
            label="All datasets"
          />
          <CustomTab value="shared" label="Shared with you" />
          <CustomTab
            value="competition"
            style={{ borderTopRightRadius: "5px" }}
            label="Collaboration"
          />
        </CustomTabs>
        <Paper variant="outlined" style={{ marginBottom: "8px" }}>
          <TabPanel className="tab-Panel" value={"all"}>
            <DatasetList filter={value} />
          </TabPanel>
          <TabPanel className="tab-Panel" value={"shared"}>
            <DatasetList filter={value} />
          </TabPanel>
          <TabPanel className="tab-Panel" value={"competition"}>
            <DatasetList filter={value} />
          </TabPanel>
        </Paper>
      </TabContext>
    </Box>
  );
};

export default Datasets;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const UpdatePasswordDialog = ({
  open,
  onClose,
  onUpdate,
  device,
  isLoading,
  apiErrors,
}) => {
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!passwords.password) {
      newErrors.password = "Password is required";
    }
    if (!passwords.confirmPassword) {
      newErrors.confirmPassword = "Please confirm password";
    }
    if (passwords.password !== passwords.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getFieldError = (fieldName) => {
    return apiErrors?.[fieldName]?.[0] || validationErrors[fieldName] || "";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (validationErrors[name]) {
      setValidationErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleClose = () => {
    setPasswords({
      password: "",
      confirmPassword: "",
    });
    setValidationErrors({});
    onClose();
  };

  const handleUpdate = () => {
    if (validateForm()) {
      const updateData = { password: passwords.password };
      onUpdate(updateData);
      setPasswords({
        password: "",
        confirmPassword: "",
      });
    }
  };

  const formatApiErrors = (errors) => {
    if (!errors) return null;
    return Object.entries(errors)
      .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
      .join("\n");
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box p={2}>
        <DialogTitle>Update Password for {device?.username}</DialogTitle>
        <DialogContent>
          {apiErrors && (
            <Box mb={2}>
              <Alert severity="error" style={{ whiteSpace: "pre-line" }}>
                {formatApiErrors(apiErrors)}
              </Alert>
            </Box>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "16px",
            }}>
            <TextField
              name="password"
              label="New Password"
              type="password"
              fullWidth
              value={passwords.password}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("password")}
              helperText={getFieldError("password")}
            />
            <TextField
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              fullWidth
              value={passwords.confirmPassword}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("confirmPassword")}
              helperText={getFieldError("confirmPassword")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Box style={{ padding: "16px" }}>
            <Button onClick={handleClose} color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleUpdate}
              color="primary"
              variant="contained"
              disabled={isLoading}>
              {isLoading ? "Updating..." : "Update Password"}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpdatePasswordDialog; 
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  CloseRounded,
} from "@material-ui/icons";
import { valuesToDefects } from "utils/format";
import { SwitchTransition, CSSTransition } from "react-transition-group";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    height: "100vh",
  },
  header: {
    display: "flex",
    height: "60px",
    lineHeight: "1.5",
    marginTop: "10px",
    width: "80%",
    alignSelf: "center",
    justifyContent: "space-between",
  },
  ImageCounter: {
    color: "rgba(255, 255, 255, 1)",
    padding: "8px 0px",
    fontSize: "1em",
    marginLeft: "auto",
    marginRight: "auto",
    alignSelf: "center",
  },
  closeButton: {
    color: "rgba(255, 255, 255, 1)",
    padding: "8px 0px",
    fontSize: "2em",
  },
  imageDiv: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    margin: 0,
    overflow: "hidden",
    height: "80vh",
    width: "100%",
  },
  imageScrollDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0, 0, 0, 1)",
    overflow: "auto",
    width: "80%",
    cursor: "zoom-out",
    height: "calc(95vh - 60px)",
  },
  leftButton: {
    flexGrow: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "8px",
    alignSelf: "center",
    textAlign: "center",
    cursor: "pointer",
    width: "10%",
    fontSize: "2em",
    height: "100%",
  },
  rightButton: {
    flexGrow: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "8px",
    alignSelf: "center",
    textAlign: "center",
    cursor: "pointer",
    width: "10%",
    fontSize: "2em",
    height: "100%",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  imageSummary: {
    display: "grid",
    gridGap: 0,
    cursor: "zoom-out",
    width: "100%",
    height: "calc(95vh - 60px)",
    gridTemplateRows: "repeat(auto-fit, minmax(30%, 1fr))",
    gridTemplateColumns: "repeat(auto-fit, minmax(25%, 1fr))",
  },
}));

export default function ImageModelData({
  clickCallback,
  sampleImages,
  selectedImage,
  imageUrl,
  dataset_type,
}) {
  const classes = useStyles();
  const [sample, setSample] = useState({ ...selectedImage });

  console.log("selectedImage-ImageModelData", sample);

  const handleClose = () => {
    clickCallback();
  };

  const handleLeftClick = () => {
    let index = sample.index;
    if (index <= 0) {
      index = sampleImages.length - 1;
    } else {
      index = sample.index - 1;
    }
    const defects = valuesToDefects(sampleImages[index]);
    setSample({ ...sampleImages[index], defects, index });
  };

  const handleRightClick = () => {
    let index = sample.index;
    if (index >= sampleImages.length - 1) {
      index = 0;
    } else {
      index = sample.index + 1;
    }
    const defects = valuesToDefects(sampleImages[index]);
    setSample({ ...sampleImages[index], defects, index });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div style={{ width: "calc(33.33%)" }}>
          {sample.index > 0 && (
            <div className="bold-400-12" style={{ color: "#ffffff" }}>
              {dataset_type === "medical" && `${sample.label}`}
              {dataset_type === "autonomous" && `${sample.label}`}
              {dataset_type === "industrial" &&
                `${sample.part}, ${sample.voltage}kV, ${sample.material}, ${sample.company}`}
            </div>
          )}
        </div>

        <div className={classes.ImageCounter} style={{ width: "calc(33.33%)" }}>
          &nbsp;{" "}
          {sample.index > 0 && `${sample.index + 1}/${sampleImages.length}`}
        </div>

        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseRounded fontSize={"inherit"} />
        </IconButton>
      </div>

      <div className={classes.imageDiv}>
        <Button
          disableRipple
          className={classes.leftButton}
          onClick={handleLeftClick}>
          <ArrowBackIosRounded fontSize={"inherit"} />
        </Button>

        {sample.index > 0 ? (
          <div className={classes.imageScrollDiv} onClick={handleClose}>
            <SwitchTransition mode={"out-in"}>
              <CSSTransition
                key={sample.id}
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                classNames="fade">
                <img
                  style={{ width: "100%" }}
                  src={`${imageUrl}${sample.data_id}.jpg`}
                  alt={sample.company}
                />
              </CSSTransition>
            </SwitchTransition>
          </div>
        ) : (
          <div className={classes.imageSummary} onClick={handleClose}>
            {sampleImages.slice(1).map((image, index) => {
              /*
              TODO: the key must not use index. we need to change this to sample.id
              */
              return (
                <img
                  key={image.imageID + index}
                  src={`${imageUrl}${image.data_id}.jpg`}
                  alt={image.company}
                  style={{ width: "inherit" }}
                />
              );
            })}
          </div>
        )}

        <Button
          disableRipple
          onClick={handleRightClick}
          className={classes.rightButton}>
          <ArrowForwardIosRounded fontSize={"inherit"} />
        </Button>
      </div>
    </div>
  );
}

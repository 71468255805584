import React, { useContext, useEffect, useState } from "react";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";

import "./style.css";
import { Box, CircularProgress, Grid } from "@material-ui/core";

import { useHistory } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import CollaborationCard from "Components/CollaborationCard";

const CollaborationList = (props) => {
  const {
    state: {
      competitions,
      competitions: { loading, list },
    },
    getCollaborations,
  } = useContext(GlobalContext);
  let history = useHistory();
  const listEndRef = React.useRef(null);
  const appInsights = useAppInsightsContext();
  const trackCollaborationCard = useTrackMetric(
    appInsights,
    "CollaborationCard",
    {}
  );

  // Extract page number from next URL
  const getNextPageNumber = (nextUrl) => {
    if (!nextUrl) return null;
    const url = new URL(nextUrl);
    const pageParam = url.searchParams.get('page');
    return pageParam ? parseInt(pageParam) : null;
  };

  // Intersection Observer setup
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && competitions.next && !loading) {
          const nextPage = getNextPageNumber(competitions.next);
          if (nextPage) {
            getCollaborations(nextPage);
          }
        }
      },
      { threshold: 0.1 }
    );

    const currentRef = listEndRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [competitions.next, loading]);

  useEffect(() => {
    if (list && list.length === 0 && !loading) {
      getCollaborations();
    }
  }, []);

  const handleClick = (event, row) => {
    history.push({
      pathname: `/collaborations/${row.slug}/details/`,
      state: { ...row },
    });

    trackCollaborationCard({
      competitionName: row?.title,
      competitionSlug: row.slug,
    });
  };

  return list && list.length > 0 ? (
    <Box
      style={{
        display: "flex",
        flexWrap: "wrap",
        marginTop: 16,
        marginBottom: 16,
        rowGap: 16,
        columnGap: 15,
      }}>
      {list.map((competition, index) => (
        <CollaborationCard
          key={competition.dataset}
          competition={competition}
          index={index}
          onClick={(event) => handleClick(event, competition)}
        />
      ))}
      <div ref={listEndRef} style={{ width: '100%', height: '20px' }} />
    </Box>
  ) : loading ? (
    <Grid
      style={{ marginTop: 24 }}
      container
      justifyContent="center"
      alignItems="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid
      style={{ marginTop: 24 }}
      container
      justifyContent="center"
      alignItems="center">
      <div>No collaborations found</div>{" "}
    </Grid>
  );
};

export default CollaborationList;

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { API_URL } from "services/api/config";
import { withStyles } from "@material-ui/styles";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

let cancelSearchToken,
  selectedUsers = new Set();

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: "var(--primary)",
    height: 24,
    fontSize: 12,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#fff",
    },
    "& .MuiChip-deleteIcon": {
      color: "#fff",
      height: 18,
      width: 18,
    },
    "&:after": {
      content: '""',
      right: 8,
      top: 6,
      height: 12,
      width: 12,
      position: "absolute",
      backgroundColor: "#333333",
      zIndex: -1,
    },
  },
})(Autocomplete);

const ShareDialog = ({ title, dataset, datasetKey, show, closeCallback }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getSearchResults = (e, value) => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");

    //Check if there are any previous pending requests
    if (typeof cancelSearchToken != typeof undefined) {
      cancelSearchToken.cancel("canceled");
    }
    // Save the cancel token for the current request
    cancelSearchToken = axios.CancelToken.source();

    const config = {
      method: "GET",
      url: `${API_URL}/search-user/${e.target.value}/`,
      data: { claps: 1 },
      headers: {
        Authorization: `Token ${token}`,
      },
      cancelToken: cancelSearchToken.token, //Pass the cancel token to the current request
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        setOptions(data);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (error.response) {
          setError("Please make sure you are logged in.");
        }
        // the loadding continue if the the request is canceled due to user typing.
        if (error.message !== "canceled") {
          setLoading(false);
          setError(null);
        }
      });
  };

  const setShareDataset = (users) => {
    setLoading(true);
    const token = localStorage.getItem("_key_usr_tkn");
    const config = {
      method: "GET",
      url: `${API_URL}/dataset/${datasetKey}/share/?mit=${users}&type=users`,
      data: { claps: 1 },
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        const data = res.data;
        setLoading(false);
        setError(null);
        closeCallback(true);
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
        } else {
          setError("Make sure you are connected to the internet.");
        }
        if (error.message !== "canceled") {
          setLoading(false);
        }
      });
  };

  const handleShare = (e) => {
    setShareDataset(Array.from(selectedUsers).toString());
  };

  return (
    <Dialog
      open={show}
      onClose={() => closeCallback(false)}
      aria-labelledby="form-dialog-title">
      <DialogContent>
        <DialogContentText id="form-dialog-title">
          <span className="grey-italic-text">
            Share {title} having ID {datasetKey}
          </span>
        </DialogContentText>

        <CustomAutocomplete
          id="asynchronous-search"
          multiple
          style={{ width: 500 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          filterSelectedOptions
          getOptionLabel={(option) => {
            return `${option.first_name} (${option.email})`;
          }}
          renderOption={(props, option) => {
            return (
              <Grid key={props.user_id} container alignItems="center">
                <Avatar
                  alt={`${props.first_name} ${props.last_name} `}
                  src={props.profile_image}
                />
                <Box ml={1}>
                  {props.first_name} {props.last_name} ({props.email})
                </Box>
              </Grid>
            );
          }}
          options={options}
          loading={loading}
          onChange={(e, value, reason) => {
            selectedUsers = new Set();
            value.forEach((user) => {
              selectedUsers.add(user.id);
            });
          }}
          renderInput={(params) => (
            <TextField
              margin="dense"
              onChange={getSearchResults}
              {...params}
              label="Search for user to share or invite"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <Collapse in={error !== null}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setError(null);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {error}
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeCallback(false)} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleShare} color="primary">
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;

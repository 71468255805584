import React from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const AnimatedText = (props) => {
  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        classNames="fade"
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        key={props.text}>
        <span style={{ backgroundColor: "inherit " }}>{props.text}</span>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default AnimatedText;

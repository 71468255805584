import {
  Avatar,
  Box,
  Collapse,
  Grid,
  IconButton,
  Portal,
  TableCell,
  TableRow,
} from "@material-ui/core";

import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Alert } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";

import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import {
  BookMarkIcon,
  CopyIcon,
  ShareIcon,
  EditIcon,
  ArchiveIcon,
  StyledRating,
} from "Components/Icon";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { StarBorderOutlined, StarRate } from "@material-ui/icons";
import SaveDataset from "../SaveDataset";
import ShareDialog from "./ShareDialog";

// { id, name, date, members, rating };
function DataSetRow(props) {
  const { row, labelId, onClick, ...rowProps } = props;
  const [open, setOpen] = useState(false);
  const [share, setShare] = useState(false);
  const [shareSuccess, setShareSuccess] = useState(false);
  const [copied, setCopied] = useState(false);
  const dataset = row.dataset_meta && {
    dataset_key: row.dataset_key,
    count: row.dataset_meta.count,
    dataset_type: row.dataset_type,
    groups: row.dataset_meta.groupedDefectData,
    data_samples: row.data_samples,
  };
  const d = new Date(row.updated_date); /* midnight in China on April 13th */
  //console.log("ROW", row);
  const rowOpen = () => {
    setOpen(!open);
    onClick();
  };

  const showCopyMessage = (e) => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const copyDatasetId = (e) => {
    e.stopPropagation(); // stop the row from being clicked
  };

  const handleShareOpen = (e) => {
    e.stopPropagation();
    setShare(true);
  };

  const handleShareClose = (share) => {
    setShare(false);
    setShareSuccess(share);
    setTimeout(() => {
      setShareSuccess(false);
    }, 2000);
  };

  return (
    <React.Fragment key={`${row.id}-1`}>
      <TableRow key={`${row.id}-2`} {...rowProps} onClick={rowOpen}>
        <TableCell style={{ width: "200px" }}>
          <Grid container direction="row" alignItems="center">
            <IconButton
              className="icon-button"
              aria-label="bookmark"
              color="primary">
              <BookMarkIcon selected={true} />
            </IconButton>
            <IconButton
              className="icon-button"
              aria-label="details"
              color="primary">
              <EditIcon className="icon" />
            </IconButton>
            <IconButton
              onClick={handleShareOpen}
              className="icon-button"
              aria-label="share"
              color="primary">
              <ShareIcon className="icon" />
            </IconButton>
          </Grid>
        </TableCell>
        <TableCell id={labelId}>
          <Grid container alignItems="center">
            <Grid xs={8} item>
              {row.dataset_key}
            </Grid>
            <Grid item>
              <CopyToClipboard text={row.dataset_key} onCopy={showCopyMessage}>
                <IconButton
                  onClick={copyDatasetId}
                  className="icon-button"
                  aria-label="share"
                  color="primary">
                  <CopyIcon className="icon" />
                </IconButton>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell>
          {d.toLocaleString("en-US", {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })}
        </TableCell>
        <TableCell>
          <AvatarGroup spacing={5} classes={{ avatar: "avatar-small" }} max={5}>
            {row.users &&
              row.users.map((user) => (
                <Avatar
                  key={user.user_id}
                  className="avatar-small"
                  alt={user.first_name}
                  src={`${user.profile_image}`}
                />
              ))}
          </AvatarGroup>
        </TableCell>
        <TableCell>
          <IconButton
            className="icon-button"
            aria-label="archive"
            color="primary">
            <ArchiveIcon className="icon" />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow key={`${row.id}-3`}>
        <TableCell
          style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} marginTop={3}>
              <SaveDataset isSaving={false} dataset={dataset} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Portal>
        {copied && (
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <Alert
              variant="filled"
              iconMapping={{
                success: (
                  <CheckCircleOutlineRoundedIcon style={{ fontSize: 24 }} />
                ),
              }}
              severity="success">
              Dataset ID successfully copied
            </Alert>
          </Snackbar>
        )}

        {shareSuccess && (
          <Snackbar
            open={shareSuccess}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <Alert
              variant="filled"
              iconMapping={{
                success: (
                  <CheckCircleOutlineRoundedIcon style={{ fontSize: 24 }} />
                ),
              }}
              severity="success">
              Dataset successfully shared.
            </Alert>
          </Snackbar>
        )}
      </Portal>

      <ShareDialog
        datasetKey={row.dataset_key}
        dataset={dataset}
        title={row.title}
        show={share}
        closeCallback={handleShareClose}
      />
    </React.Fragment>
  );
}

export default DataSetRow;

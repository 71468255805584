import { Box, Grid, Paper } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import "./style.css";

import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { GlobalContext } from "Context/GlobalState";
import { generateGradient } from "utils/gradient";

const CustomTooltip = ({ active, payload, label, total }) => {
  if (active && payload && payload.length) {
    return (
      <Paper className="defect-bar-tooltip" variant="outlined">
        <div
          style={{ color: payload[0]?.payload?.fill }}
          className={payload[0].name}
          key={`item-${payload[0].name}`}>
          <b>{`${((payload[0].value / total) * 100).toFixed(2)}%`}</b> of images
          in the
        </div>

        <div
          style={{ color: payload[0]?.payload?.fill }}
          className={payload[0].name}
          key={`item-${payload[0].name}-value`}>
          dataset have <b>{`${payload[0].name}`}</b> label
        </div>
      </Paper>
    );
  }

  return null;
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central">
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  );
};

const RelativeClassPerImageTab = (props) => {
  const {
    dataset,
    dataset: { count},
  } = props;
  const {
    sidebar
  } = useContext(GlobalContext);
  const { open } = sidebar;
  const [classes, setClasses] = useState([]);
  const [colors, setColors] = useState(["#3576A7"]);

  useEffect(() => {
    const data = [];

    if (dataset?.data_items_label_count) {
      Object.entries(dataset?.data_items_label_count).forEach(
        ([key, value]) => {
          data.push({ name: key, value: value });
        }
      );
      setClasses(data);
      setColors(generateGradient("#363534", "#A9AFB0", data.length));
    }
  }, []);

  return (
    <Grid container direction="column" justify="flex-start">
      <Box
        mt={count?.defect ? 0 : 2}
        pl={0}
        pr={2}
        pb={1}
        className="inner-content">
        <ResponsiveContainer width={open ? "99.8%" : "100%"} height="100%">
          <PieChart
            width={"100%"}
            height={319}>
            <Pie
              data={classes}
              cx={"100%" / 2}
              cy={319 / 2}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={120}
              fill="#8884d8"
              dataKey="value">
              {classes.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>

            <Tooltip
              content={
                <CustomTooltip total={dataset?.unique_data_items_count} />
              }
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Grid>
  );
};

export default RelativeClassPerImageTab;
